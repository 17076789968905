'use strict';

import Vue from 'vue';
import Router from 'vue-router';

import Download from '../views/Download.vue';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import ResetPassword from '../views/ResetPassword.vue';
import User from '../views/User.vue';
import DeleteUser from '../views/DeleteUser.vue';
import LostPhone from '../views/LostPhone.vue';
import Validate from '../views/Validate.vue';
import ValidateFromLink from '../views/ValidateFromLink.vue';
import FactoryResetRequest from '../views/FactoryResetRequest';
import ProcessFactoryResetRequest from '../views/ProcessFactoryResetRequest';

import store from '../store';

import util from '../util.js';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/user',
      name: 'user',
      component: User
    },
    {
      path: '/download',
      name: 'download',
      component: Download
    },
    {
      path: '/user/delete',
      name: 'userDelete',
      component: DeleteUser,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        overrideAuth: true
      }
    },
    {
      path: '/reset',
      name: 'reset',
      component: ResetPassword,
      meta: {
        overrideAuth: true
      }
    },
    {
      path: '/lost',
      name: 'lost',
      component: LostPhone
    },
    {
      path: '/factoryresetrequest',
      name: 'factoryResetRequests',
      component: FactoryResetRequest
    },
    {
      path: '/processfactoryresetrequest',
      name: 'processFactoryResetRequest',
      component: ProcessFactoryResetRequest
    },
    {
      path: '/validate',
      name: 'validate',
      component: Validate,
      meta: {
        overrideAuth: true
      }
    },
    {
      path: '/validateFromLink',
      name: 'validateFromLink',
      component: ValidateFromLink,
      meta: {
        overrideAuth: true
      }
    },
  ]
});

router.beforeEach((to, from, next) => {
  const loggedIn = Boolean(store.loggedIn);

  if (!loggedIn && !to.meta.overrideAuth) {
    return next({ name: 'login' });
  }

  if (loggedIn && to.name === 'login') {
    return next({ name: 'home' });
  }

  next();
});

export default router;
