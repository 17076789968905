import axios from 'axios';

import config from '../config';
import util from '../util';

const accountApi = axios.create({
  baseURL: config.apiBaseUrl,
});
const loggerName = 'accountApiClient';

/**
 * Proxy GET /apple/nonce to rest-api
 *
 * @async
 * @returns {Promise<string>}
 */
export async function getAppleNonce() {
  try {
    const res = await accountApi.get('/apple/nonce', {
      headers: util.getRequestHeaders(),
    });

    return res.data;
  } catch (err) {
    console.error(err);
    await util.sendErrorLog(loggerName, 'getNonceError', err.message);
  }
}

/**
 * Proxy POST /apple/session to rest-api
 *
 * @async
 * @param {Object} sessionPayload - payload for rest-api /apple/session
 * @returns {Promise<string>} account-api manage token
 */
export async function postAppleSession(sessionPayload) {
  try {
    const res = await accountApi.post('/apple/session', sessionPayload, {
      headers: util.getRequestHeaders()
    });

    return res.data;
  } catch (err) {
    console.error(err);
    await util.sendErrorLog(loggerName, 'postAppleSessionError', err.message);
  }
}
