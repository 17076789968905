<template>
  <v-container fluid :class="{'pl-2 pr-2': $vuetify.breakpoint.smAndDown}">
    <v-layout row wrap>
      <v-flex xs12 sm10 md8 lg6>
        <v-sheet id="content" class="elevation-1 pa-3">
          <center><img src="img/asl3_illustration.png" width="174" height="174" alt="Smart Lock"></center>

          <h3 class="mt-5">{{ $t('factory-reset.process.title', {LockName}) }}</h3>

          <p class="mt-3">{{ $t('factory-reset.process.overview') }}</p>

          <div class="button-group-factory-reset">
            <v-btn color="primary" class="factory-reset-button" @click="clickProcessFactoryResetRequest(requestID, true, serialNumber)">{{ $t('common.words.yes') }}</v-btn>
            <v-btn class="factory-reset-button" @click="clickProcessFactoryResetRequest(requestID, false, serialNumber)">{{ $t('common.words.no') }}</v-btn>
          </div>

          <p id="learn-more"><a href="https://support.august.com/how-do-i-factory-reset-my-lock-BkH1D8y0uG" target="_blank">{{ $t('factory-reset.process.learn') }}</a></p>
        </v-sheet>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import config from '../config/index.js';
  import util from '../util.js';

  export default {
    name: 'processFactoryResetRequest',
    data() {
      return {
        placeHolderImg: config.placeHolderImg,
        LockName: this.$route.params.LockName,
        requestID: this.$route.params.id,
        serialNumber: this.$route.params.serialNumber,
      };
    },
    methods: {
      clickProcessFactoryResetRequest: async function (requestID, approved, serialNumber) {
        try {
	        const options = {headers: util.getRequestHeaders()};
	        const data = {
		        requestID,
            approved,
            serialNumber,
	        };
	        const result = await axios.put(`${config.apiBaseUrl}/factoryresetrequests`, data, options);
	        this.$router.push({
		        name:'factoryResetRequests',
          });
          const alertText = approved ?
                            this.$i18n.t('factory-reset.process.success.approved') :
              this.$i18n.t('factory-reset.process.success.denied');

	        this.$emit('alert', {
		        text: alertText,
		        type: 'success',
	        });
        } catch (err) {
          console.log(err);

	        this.$emit('alert', {
		        text: this.$i18n.t('factory-reset.process.failure'),
		        type: 'error',
          });

	        await util.sendErrorLog(this.$options.__file, 'clickProcessFactoryResetRequestError', err.message);
        }
      },
    },
  };
</script>

<style>
  #content {
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .button-group-factory-reset {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .factory-reset-button-no {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #learn-more > a {
    position: absolute;
    width: 95%;
    bottom: 10%;
    text-align: center;
    color: #767676;
    text-decoration: none;
    text-transform: uppercase;
  }
</style>
