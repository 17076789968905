'use strict';

import { isObject } from '../util.js';

const RE_TOKEN_LIST_VALUE = /^(?:\d)+/; // numbered token/variable, f.e. {0}, {1}
const RE_TOKEN_NAMED_VALUE = /^(?:\w)+/; // named token/variable, f.e. {foo}, {bar}

/**
 * Custom vue-i18n formatter to support nested variables
 *
 * vue-i18n does not support nested variables like i18n-next does on the server side;
 * this implements the missing functionality.
 *
 * This is basically the the implementation from vue-i18n plus support for nested object variables.
 *
 * @see vue-i18n/src/format.js
 * @see https://kazupon.github.io/vue-i18n/guide/formatting.html#custom-formatting
 * @see https://github.com/kazupon/vue-i18n/issues/584
 * @see https://github.com/kazupon/vue-i18n/issues/927
 */
export default class NestedVariableFormatter {
  #caches;
  #globalValues = {};

  /**
   *
   * @param {Object} globals - key/value pairs of variables to be available for any string
   */
  constructor(globals = {}) {
    this.#caches = {};
    this.#globalValues = globals;
  }

  /**
   * resolves variable markers in translated strings
   *
   * @param {string} message - translated message, f.e. 'Hi {name}'
   * @param {Object|Array} values - values provided to the $t() and $te() functions
   * @return {string[]} interpolated values
   */
  interpolate = (message, values) => {
    const mergedValues = Object.assign({}, this.#globalValues, values);

    let tokens = this.#caches[message];
    if (!tokens) {
      tokens = parse(message);
      this.#caches[message] = tokens;
    }

    // this has been added over the core vue-i18n implementation
    const flattenedValues = flattenObject(mergedValues);

    return compile(tokens, flattenedValues);
  }
}

function flattenObject(obj = {}, prefix = '') {
  return Object.keys(obj).reduce((params, key) => {
    const pre = prefix.length ? prefix + '.' : '';

    if (typeof obj[key] === 'object') {
      Object.assign(params, flattenObject(obj[key], pre + key));
    } else {
      params[pre + key] = obj[key];
    }

    return params;
  }, {});
}


function parse(format) {
  const tokens = [];
  let position = 0;

  let text = '';
  while (position < format.length) {
    let char = format[position++];
    if (char === '{') {
      if (text) {
        tokens.push({ type: 'text', value: text });
      }

      text = '';
      let sub = '';
      char = format[position++];
      while (char !== undefined && char !== '}') {
        sub += char;
        char = format[position++];
      }
      const isClosed = (char === '}');

      const type = RE_TOKEN_LIST_VALUE.test(sub)
        ? 'list'
        : isClosed && RE_TOKEN_NAMED_VALUE.test(sub)
          ? 'named'
          : 'unknown';

      tokens.push({ value: sub, type });
    } else if (char === '%') {
      // when found rails i18n syntax, skip text capture
      if (format[position] !== '{') {
        text += char;
      }
    } else {
      text += char;
    }
  }

  text && tokens.push({ type: 'text', value: text });

  return tokens;
}


function compile(tokens, values) {
  const compiled = [];
  let index = 0;

  const mode = Array.isArray(values)
    ? 'list'
    : isObject(values)
      ? 'named'
      : 'unknown'
  if (mode === 'unknown') {
    return compiled;
  }

  while (index < tokens.length) {
    const token = tokens[index];

    switch (token.type) {
      case 'text':
        compiled.push(token.value);
        break;

      case 'list':
        compiled.push(values[parseInt(token.value, 10)]);
        break;

      case 'named':
        if (mode === 'named') {
          compiled.push(values[token.value]);
        } else {
          if (process.env.NODE_ENV !== 'production') {
            console.warn(`Type of token '${token.type}' and format of value '${mode}' don't match!`);
          }
        }
        break;

      case 'unknown':
        if (process.env.NODE_ENV !== 'production') {
          console.warn(`Detected 'unknown' type of token!`);
        }
        break;
    }

    index++;
  }

  return compiled;
}
