<template>
  <v-menu
    transition="slide-y-transition"
    offset-y
    max-height="350"
  >
    <template v-slot:activator="{ on }" class="ma-0 pa-0">
      <v-btn
        v-on="on"
        flat
        class="text-transform-none pl-2 pr-2"
      >
        <v-icon class="mr-2">language</v-icon>
        <span v-if="showCurrentLanguage">{{ language.languageNameNative }}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-tile
        v-for="(language, i) in languages"
        :key="i"
        @click="() => {}"
      >
        <v-list-tile-title @click="$emit('switchLanguage', language.locale)">{{ language.languageNameNative }}</v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'LanguageSelector',
  props: ['languages', 'language', 'showCurrentLanguage'],
}
</script>
