'use strict';

import _ from 'lodash';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';

import config from '../config/index.js';
import store from '../store';
import util from '../util.js';
import NestedVariableFormatter from './nestedvariableformatter.js';

Vue.use(VueI18n);

let languages;
const messages = {};

// These are the current languages that are completely translated.
// TODO: Don't hard-code this.
const availableLanguages = ['source', 'en-US', 'es-ES', 'de-DE', 'es-MX', 'fr-FR', 'lt-LT', 'lv-LV', 'pl-PL', 'tr-TR', 'zh-CN', 'ko-KR'];

export let i18n;

/**
 * Dedicated VueI18n initializer so we can control flow when initializing the app
 *
 */
export function initVueI18n() {
  i18n = new VueI18n({
    locale: 'en-US',
    formatter: new NestedVariableFormatter({brand: store.brand}),
    messages,
  });
}

/**
 * Get the user's locale - either navigator.language or the stored locale
 *
 * @returns {{country: string, language: string}}
 */
export function getUserLocale() {
  let storedLocale = store.locale;
  if (storedLocale === 'source') {
    storedLocale = 'en-US';
  }
  const browserLocale = navigator.language;

  let [language, country] = (storedLocale || browserLocale).split('-');
  if (!country) {
    // NOTE this is not always correct, but good enough for now
    //   f.e. if we get a locale of just "en", we could not assume
    //   a country "EN"; the correct country would be either "GB" or "US"
    country = language;
  }

  return {
    country,
    language,
  }
}

/**
 * Determines the user's language settings and loads translation as needed
 *
 * @return {Promise}
 */
export async function initLanguages() {
  let {language, country} = getUserLocale();

  // in some cases browsers return lowercase country
  let locale = `${language}-${country.toUpperCase()}`;

  // always load english (source) as fallback
  const loadLanguages = ['source'];
  if (locale !== i18n.locale) {
    // load translation if needed
    loadLanguages.push(locale);
  }

  await Promise.all(loadLanguages.map(async (locale) => {
    try {
      console.log(`loading ${locale}`);

      const {data: translations} = await axios.get(`/translations/translations.${locale}.json`);
      console.log({
        'i18n.locale': i18n.locale,
        locale,
        translations,
      });

      messages[(locale === 'source'? 'en-US' : locale)] = translations;
    } catch (err) {
      // ignore, carry on
      console.error(`failed to load language '${locale}'`, err);
      await util.sendErrorLog('i18n.js', 'initLanguagesError', err.message, {
        data: {
          storedLocale: store.locale,
          browserLocale: navigator.language,
          locale,
        },
      });
    }
  }));

  if (config.enableTranslations === false) {
    console.log('⚠️ forcing disabled translation');
    locale = 'en-US';
    await setCurrentLanguage('en-US');
  }

  i18n.locale = locale;
  i18n.setLocaleMessage(locale, messages[locale]);
}

/**
 * Get a list of languages we have translations for.
 *
 * @returns {Promise<Array>} An array of language objects.
 */
export async function getAvailableLanguages() {
  if (!languages) {
    const result = await axios.get(`/translations/languages.json`);
    languages = _.get(result, 'data', []);
  }

  languages = languages.filter(item => {
    return availableLanguages.includes(item.locale);
  });
  languages = languages.sort((lhs, rhs) => {
    if (rhs.locale === 'source') {
      return 1;
    } else {
      return lhs.languageNameNative - rhs.languageNameNative;
    }
  });

  return languages;
}

/**
 * Get the current language that is being used.
 *
 * @returns {Object} A language object.
 */
export async function getCurrentLanguage() {
  if(!languages) {
    await getAvailableLanguages();
  }
  return _.find(languages, { locale: i18n.locale }) || _.find(languages, { locale: 'source' });
}

/**
 * Set the language being used.
 *
 * @param {string} locale - The 2 digit language code and 2 digit country code, f.e. de-DE
 * @returns {Promise<undefined>}
 */
export async function setCurrentLanguage(locale) {
  if (!messages[locale]) {
    console.log(`loading language ${locale}`);
    const response = await axios.get(`/translations/translations.${locale}.json`);
    messages[locale] = response.data;
    i18n.setLocaleMessage(locale, messages[locale]);
  }

  i18n.locale = locale;
  store.locale = locale
}
