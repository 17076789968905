<template>
  <v-app>
    <!-- Alert to user -->
    <v-snackbar auto-height top v-model="alert.visible" :color="alert.type" :timeout="alert.timeout">
      {{ alert.text }}
      <v-btn dark flat @click="alert.visible = false"><v-icon>close</v-icon></v-btn>
    </v-snackbar>

    <!-- Header -->
    <v-toolbar app fixed dense :clipped-left="$vuetify.breakpoint.lgAndUp">
      <v-toolbar-side-icon @click.stop="drawer = !drawer" v-if="!!user"></v-toolbar-side-icon>
      <v-toolbar-title>
        <img v-if="brandID === 'yale'" src="/img/yale_300x.png" height="35" alt="Yale" class="pa-0 mt-2">
        <img v-else-if="brandID === 'gateman'" src="/img/gateman_300x.png" height="35" alt="Gateman" class="pa-0 mt-2">
        <img v-else src="/img/august_300x.png" height="35" alt="August" class="pa-0 mt-2">
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <language-selector
        v-if="enableTranslations && !isLoggedIn()"
        v-bind:languages="languages"
        v-bind:language="language"
        v-bind:showCurrentLanguage="true"
        @switchLanguage="switchLanguage"
      ></language-selector>
    </v-toolbar>

    <!-- Content -->
    <v-content>
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view
          @alert="eventAlert"
          @refreshUser="eventRefreshUser"
          @extendSession="extendSession"
          @login="eventLogin"
          @logout="eventLogout"
        />
      </transition>
    </v-content>

    <!-- Sidebar -->
    <v-navigation-drawer
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      v-if="!!user && isLoggedIn()"
      fixed
      app
      :mini-variant="mini"
    >
    <v-layout column fill-height>
      <v-list class="pa-1">
        <v-list-tile v-if="mini" @click.stop="mini = !mini">
          <v-list-tile-action>
            <v-tooltip right>
              <v-icon slot="activator">chevron_right</v-icon>
              <span>{{ $t('nav.expand.label') }}</span>
            </v-tooltip>
          </v-list-tile-action>
        </v-list-tile>

        <v-list-tile avatar tag="div" v-if="!mini">
          <v-list-tile-avatar>
            <img v-if="!!user.info.imageInfo" :src="user.info.imageInfo.thumbnail.secure_url">
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title>{{ user.info.FirstName }}</v-list-tile-title>
          </v-list-tile-content>

          <v-list-tile-action>
            <v-btn icon @click.stop="mini = !mini">
              <v-tooltip right>
                <v-icon slot="activator">chevron_left</v-icon>
                <span>{{ $t('nav.collapse.label') }}</span>
              </v-tooltip>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>

      <v-list class="pt-0" dense>
        <v-divider light></v-divider>

        <v-list-tile
          v-for="item in items"
          :key="$t(item.title)"
          @click="$router.push(item.path)"
          :value="active(item)"
        >
          <v-list-tile-action>
            <v-tooltip right :disabled="!mini">
              <v-icon slot="activator">{{ item.icon }}</v-icon>
              <span>{{ $t(item.title) }}</span>
            </v-tooltip>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{ $t(item.title) }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-divider></v-divider>

        <v-list-tile @click.prevent="clickLogout()" v-if="isLoggedIn()">
          <v-list-tile-action>
            <v-tooltip right :disabled="!mini">
              <v-icon slot="activator">exit_to_app</v-icon>
              <span>{{ $t('common.buttons.logout.label') }}</span>
            </v-tooltip>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{ $t('common.buttons.logout.label') }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile @click="$router.push('/login')" v-else>
          <v-list-tile-action>
            <v-icon></v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{ $t('common.buttons.login.label') }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>

      <v-spacer></v-spacer>

      <v-list class="pt-0" ma-0 pa-0 dense>
        <v-list-tile>
          <v-list-tile-action>
            <language-selector
              v-bind:languages="languages"
              v-bind:language="language"
              v-bind:showCurrentLanguage="!mini"
              @switchLanguage="switchLanguage"
            ></language-selector>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
      </v-layout>
    </v-navigation-drawer>

    <!-- Automatic logout warning -->
    <v-dialog
      v-model="autoLogout.showWarning"
      max-width="300"
    >
      <v-card>
        <v-card-title class="headline">{{ $t('app.logout.warning.title') }}</v-card-title>

        <v-card-text>
          <p>
            {{ $t('app.logout.warning.overview', {seconds: autoLogout.secondsUntil}) }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            @click="clickExtendSession()"
          >
            {{ $t('app.logout.warning.stay') }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            flat="flat"
            @click="clickLogout()"
          >
            {{ $t('app.logout.warning.logout') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Footer -->
    <section class="text-xs-center pt-1 pb-1">
      <span v-if="showVersions">ui v{{ uiVersion }} {{ uiEnv }} - api v{{ apiVersion }} {{ apiEnv }}</span>
    </section>
  </v-app>
</template>

<script>
import axios from 'axios';
import ms from 'ms';

import config from './config/index.js';
import {i18n, getAvailableLanguages, getCurrentLanguage, setCurrentLanguage} from './lib/i18n.js';
import util from './util.js';
import packageFile from '../package.json';

import LanguageSelector from './components/LanguageSelector.vue';

export default {
  name: 'august-account-ui',
  components: {
    LanguageSelector,
  },
  async created() {
    this.user = await util.getUser();
    this.languages = await getAvailableLanguages();
    this.language = getCurrentLanguage();
    console.log('languages: ', this.languages);
    console.log('language: ', this.language);

    if (config.showVersions) {
      const apiInfo = await util.getApiInfo();
      this.apiEnv = apiInfo.env;
      this.apiVersion = apiInfo.version;
    }

    if (this.isLoggedIn()) {
      this.startSessionExpirationTimers();
      await this.refreshUser();
    }
  },
  data() {
    return {
      user: null,
      drawer: null,
      mini: false,
      items: config.pages,
      alert: {
        visible: false,
        text: null,
        type: null,
        timeout: config.alertTimeoutInMS,
      },
      autoLogout: {
        showWarning: false,
        secondsUntil: this.getSecondsUntilLogout(),
        timers: {
          logout: null,
          warning: null,
          countdown: null,
        },
      },
      uiVersion: packageFile.version,
      uiEnv: process.env.NODE_ENV,
      apiVersion: null,
      apiEnv: null,
      showVersions: config.showVersions,
      languages: [],
      language: i18n.locale,
      enableTranslations: config.enableTranslations,
    };
  },
  computed: {
    brand: function () {
      return this.$root.brand;
    },
    brandID: function () {
      return this.$root.brandID;
    }
  },
  methods: {
    /**
     * Calculate how many seconds there are until we should automatically log the user out.
     *
     * @returns <number> Number of seconds until automatic logout.
     */
    getSecondsUntilLogout: function() {
      const sessionElapsedInMS = Date.now() - this.$root.loggedIn;
      const logoutInMS = config.sessionTimeout.logoutInMS;

      return Math.ceil((logoutInMS - sessionElapsedInMS) / ms('1 second'));
    },

    /**
     * Return true if a navigation drawer item should be active.
     * i.e. it's href is the current browser location.
     *
     * @param {Object} item - The item from the config.
     * @returns {boolean} Return true if the item should be active.
     */
    active: function(item) {
      return item.path === this.$router.currentRoute.fullPath;
    },

    /**
     * Process an 'alert' event sent from a child component.
     *
     * @param {Object} [options={}] -
     * @param {string} [options.text] - The text to show the user.
     * @param {boolean} [options.visible] - Set to false to hide the existing alert.
     * @param {string} [options.type] - Type type or color of the alert.
     * @see https://vuetifyjs.com/en/components/alerts#api
     * @returns {undefined}
     */
    eventAlert: function(options = {}) {
      if (typeof options.text !== 'undefined') {
        this.alert.text = options.text;
        this.alert.visible = true;
      }
      if (typeof options.visible !== 'undefined') {
        this.alert.visible = options.visible;
      }
      if (typeof options.type !== 'undefined') {
        this.alert.type = options.type;
      }
    },

    /**
     * Process a 'refresh user' event sent from a child component.
     *
     * @returns {Promise<undefined>}
     */
    eventRefreshUser: async function() {
      await this.refreshUser();
    },

    /**
     * Get user data from the server.
     *
     * @returns {Promise<undefined>}
     */
    refreshUser: async function() {
      this.user = await util.getUser();
    },

    /**
     * Process a 'login' event sent from a child component.
     *
     * @returns {Promise<undefined>}
     */
    eventLogin: async function () {
      await this.refreshUser();
      this.startSessionExpirationTimers();
    },

    /**
     * Check if the user is logged in.
     *
     * @returns {boolean} Return true if the user is logged in.
     */
    isLoggedIn: function() {
      return Boolean(this.$root.loggedIn);
    },

    /**
     * Log the user out.
     *
     * @returns {Promise<undefined>}
     */
    logout: async function() {
      this.autoLogout.showWarning = false;
      this.user = null;
      await util.logout();
    },

    /**
     * Process a 'logout' event sent from a child component.
     *
     * @returns {Promise<undefined>}
     */
    eventLogout: async function () {
      await this.logout();
    },

    /**
     * User clicked the button to logout.
     *
     * @returns {Promise<undefined>}
     */
    clickLogout: async function () {
      this.logout();
    },

    /**
     * Start the timers that will automatically log the user out.
     *
     * @returns {undefined}
     */
    startSessionExpirationTimers: function () {
      const sessionElapsedInMS = Date.now() - this.$root.loggedIn;
      const warningInMS = config.sessionTimeout.warningInMS - sessionElapsedInMS;
      const logoutInMS = config.sessionTimeout.logoutInMS - sessionElapsedInMS;

      this.autoLogout.timers.warning = setTimeout(() => {
        this.autoLogout.showWarning = true;

        this.autoLogout.timers.countdown = setInterval(() => {
          this.autoLogout.secondsUntil = this.getSecondsUntilLogout();
        }, ms('1 second'));
      }, warningInMS);

      this.autoLogout.timers.logout = setTimeout(() => {
        this.clearSessionExpirationTimers();
        this.logout();
      }, logoutInMS);
    },

    /**
     * Clear the timers that will automatically log the user out.
     *
     * @returns {undefined}
     */
    clearSessionExpirationTimers: function () {
      clearTimeout(this.autoLogout.timers.warning);
      clearTimeout(this.autoLogout.timers.logout);
      clearInterval(this.autoLogout.timers.countdown);
    },

    /**
     * Extend a user's session with the backend.
     *
     * @returns {Promise<undefined>}
     */
    extendSession: async function () {
      try {
        const data = {};
        const options = { headers: util.getRequestHeaders() };
        const result = await axios.put(`${config.apiBaseUrl}/login`, data, options);

        this.clearSessionExpirationTimers();
        util.setLoggedIn.call(this);
        this.startSessionExpirationTimers();

        this.eventAlert({
          text: this.$i18n.t('loginExtendSuccess'),
          type: 'success'
        });
      } catch (err) {
        console.log(err);

        this.eventAlert({
          text: this.$i18n.t('loginExtendError'),
          type: 'error'
        });

        await util.sendErrorLog(this.$options.name, 'extendSessionError', err.message);
      }
    },

    /**
     * User clicked the button to extend their session.
     *
     * @returns {Promise<undefined>}
     */
    clickExtendSession: async function () {
      await this.extendSession();
      this.autoLogout.showWarning = false;
    },

    switchLanguage: async function (locale) {
      await setCurrentLanguage(locale);
      this.language = getCurrentLanguage();
    }
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity, transform;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
  transform: translateX(-10px);
}

.highlight {
  animation: highlight 1s;
}
@keyframes highlight {
  0% { background-color: yellow }
  100% { background-color: white }
}

.text-transform-none {
  text-transform: none;
}
</style>
