'use strict';

import constants from 'august-constants';

import config from '../config';

const validBrands = ['august', 'yale', 'gateman'];

// @see https://vuejs.org/v2/guide/state-management.html#Simple-State-Management-from-Scratch
const state = {
  brand: 'august',
  manageToken: null,
  locale: null,
  loggedIn: null,
};


export function initBrand() {
  const searchParams = new URL(window.location.href).searchParams;
  const urlBrand = searchParams.get("brand") || searchParams.get('b');
  if (urlBrand && urlBrand !== state.brandID) {
    state.brand = urlBrand;
  }

  if (config.debug) {
    console.debug('initialized brand', state.brand);
  }

  return Promise.resolve();
}

export default {
  debug(...args) {
    if (config.debug) {
      console.debug(...args);
    }
  },

  dump() {
    if (config.debug) {
      console.debug('state', state);
    }
  },

  /**
   * brand setter
   *
   * @param {string} brand - raw brand ID ("august" or "yale")
   */
  set brand(brand) {
    if (!validBrands.includes(brand)) {
      console.error('invalid brand, defaulting to "august"');
    }

    state.brand = brand;
    this.debug('set brand', brand);
  },

  /**
   *
   * @see constants.Brands
   * @return {Object} brand object with its various properties
   */
  get brand() {
    return constants.Brands.getBrand(state.brand);
  },

  /**
   * getter for the raw brandID value
   * @return {string}
   */
  get brandID() {
    return state.brand;
  },


  set locale(locale) {
    state.locale = locale;
    this.debug('set locale', locale);
  },

  get locale() {
    return state.locale;
  },


  set manageToken(manageToken) {
    state.manageToken = manageToken;
    this.debug('set manageToken', manageToken);
  },

  get manageToken() {
    return state.manageToken;
  },


  set loggedIn(timestamp) {
    state.loggedIn = timestamp;
    this.debug('set loggedIn', timestamp);
  },

  get loggedIn() {
    return state.loggedIn;
  },
};
