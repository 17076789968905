<template>
  <v-container fluid :class="{'pl-2 pr-2': $vuetify.breakpoint.smAndDown}">
    <v-layout row wrap>
      <v-flex xs12 sm10 md8 lg6>
        <h2 class="pb-1">{{ $t('lost-phone.title') }}</h2>
        <p>{{ $t('lost-phone.overview') }}</p>

        <v-btn color="primary" @click="deviceLogout()">{{ $t('lost-phone.button')}}</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';

import config from '../config/index.js';
import util from '../util.js';

export default {
  name: 'lostPhone',
  data() {
    return {
      user: null,
    };
  },
  beforeCreate: async function () {
    this.user = await util.getUser();
  },
  methods: {
    deviceLogout: async function () {
      try {
        const url = `${config.apiBaseUrl}/user/logout`;
        const data = {};
        const options = {headers: util.getRequestHeaders()};
        const result = await axios.put(url, data, options);

        this.$emit('alert', {
          text: this.$i18n.t('lost-phone.success'),
          type: 'success'
        });
        this.$emit('logout', {
          message: false,
        });
      } catch (err) {
        console.log('err: ', err);
        this.$emit('alert', {
          text: this.$i18n.t('lost-phone.failure'),
          type: 'error'
        });
      }
    },
  },
};
</script>

<style>
.upper {
  text-transform: uppercase;
}
.delete-consequences {
  list-style-type: decimal;
}
.delete-consequences > li {
  margin-bottom: 1rem;
}
</style>
