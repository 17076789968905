<template>
  <v-container fluid :class="{'pl-2 pr-2': $vuetify.breakpoint.smAndDown}">
    <v-layout row wrap>
      <v-flex xs12 sm10 md8 lg6>
        <h2 class="pb-1">{{ $t('download.page.title') }}</h2>
        <p>{{ $t('download.page.overview') }}</p>
      </v-flex>
    </v-layout>

    <!-- Create a download request -->
    <v-layout row wrap class="mb-3" v-show="visibility.create">
      <v-flex xs12 sm10 md8 lg6>
        <v-card>
          <v-card-title primary-title class="pb-1 pt-3">
            <h2>{{ $t('download.create.title') }}</h2>
          </v-card-title>

          <v-card-text class="pa-0">
            <p class="pt-1 pr-3 pl-3">{{ $t('download.create.overview') }}</p>

            <v-list subheader>
              <v-list-tile
                v-for="item in includeInCopy"
                :key="item.title"
                avatar
              >
                <v-list-tile-avatar class="hidden-xs-only">
                  <v-icon :color="item.active ? 'primary' : 'grey'" large>{{ item.avatar }}</v-icon>
                </v-list-tile-avatar>

                <v-list-tile-content>
                  <v-list-tile-title v-html="item.title"></v-list-tile-title>
                  <v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title>
                </v-list-tile-content>

                <v-list-tile-action>
                  <v-switch
                    v-model="item.active"
                  ></v-switch>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              class="ma-2"
              @click="clickCreateDownload()"
              :loading="loading.create"
            >
              {{ $t('download.create.button.create') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Download request created -->
    <v-layout row wrap class="mb-3" v-show="visibility.created">
      <v-flex xs12 sm10 md8 lg6>
        <v-card>
          <v-card-title primary-title class="pb-1 pt-3">
            <h2>{{ $t('download.created.title') }}</h2>
          </v-card-title>

          <v-card-text class="pa-0">
            <p class="pt-1 pr-3 pl-3">{{ $t('download.created.overview') }}</p>
          </v-card-text>

          <v-card-actions class="pb-3 pl-3">
            <v-btn color="primary" @click="clickCreateNew()">{{ $t('download.created.button.new')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Existing downloads -->
    <v-layout row wrap v-show="visibility.downloads">
      <v-flex xs12 hidden-sm-and-up>
        <v-card>
          <v-card-title class="pb-1 pt-3">
            <h2>{{ $t('download.existing.title') }}</h2>
          </v-card-title>

          <v-card-text class="pa-0">
            <p class="pt-1 pr-3 pl-3">{{ $t('download.existing.overview') }}</p>

            <v-divider></v-divider>
            <p v-show="!downloads.length" class="pt-3 pr-3 pb-3 pl-3">{{ $t('download.existing.none') }}</p>

            <v-expansion-panel class="elevation-0" v-show="downloads.length">
              <v-expansion-panel-content
                v-for="(item,i) in downloads"
                :key="i"
                :class="{'highlight': item.highlight}"
              >
                <v-icon slot="actions" color="primary">$vuetify.icons.expand</v-icon>
                <div slot="header">{{ item.status }} {{ item.createdAt }}</div>
                <p class="pl-4">{{ $t('download.current.header.expires') }} {{ item.expiresAt }}</p>

                <v-layout>
                  <v-flex sm-6>
                    <p class="pl-4" v-show="item.status === constants.UserDownloadStatuses.AVAILABLE">
                      <a
                        :href="item.url"
                        target="_blank"
                      >{{ $t('download.current.button.download') }}</a>
                    </p>
                  </v-flex>

                  <v-flex sm-6>
                    <p class="pr-4 text-xs-right" v-show="item.status === constants.UserDownloadStatuses.AVAILABLE || item.status === constants.UserDownloadStatuses.FAILED">
                      <a
                        @click="clickDeleteDownload(item._id)"
                        style="text-decoration: underline"
                      >{{ $t('common.words.delete') }}</a>
                    </p>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex xs12 sm10 md8 lg6 hidden-xs-only>
        <v-card>
          <v-card-title primary-title class="pb-1 pt-3">
            <h2>{{ $t('download.existing.title') }}</h2>
          </v-card-title>

          <v-card-text class="pa-0">
            <p class="pt-1 pr-3 pl-3">{{ $t('download.existing.overview') }}</p>

            <v-data-table
              hide-actions
              :headers="headers"
              :items="downloads"
              item-key="name"
              class="elevation-0"
            >
              <template slot="no-data">
                <p class="text-xs-center mt-3 mb-3">{{ $t('download.existing.none') }}</p>
              </template>

              <template slot="items" slot-scope="props">
                <tr :class="{'highlight': props.item.highlight}">
                  <td>{{ props.item.createdAt }}</td>
                  <td>{{ props.item.expiresAt }}</td>
                  <td>{{ props.item.status }}</td>
                  <td class="text-xs-right">
                    <v-hover>
                      <v-tooltip bottom class="mr-3" slot-scope="{ hover }">
                        <a
                          slot="activator"
                          :href="props.item.url"
                          v-show="props.item.status === constants.UserDownloadStatuses.AVAILABLE"
                          target="_blank"
                          style="text-decoration: none;"
                        >
                          <v-icon :color="hover ? 'primary': ''">cloud_download</v-icon>
                        </a>
                        <span>{{ $t('download.current.button.download') }}</span>
                      </v-tooltip>
                    </v-hover>

                    <v-hover>
                      <v-tooltip bottom slot-scope="{ hover }">
                        <a
                          slot="activator"
                          @click="clickDeleteDownload(props.item._id)"
                          v-show="props.item.status === constants.UserDownloadStatuses.AVAILABLE || props.item.status === constants.UserDownloadStatuses.FAILED"
                        >
                          <v-icon :color="hover ? 'error': ''">delete</v-icon>
                        </a>
                        <span>{{ $t('common.words.delete') }}</span>
                      </v-tooltip>
                    </v-hover>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Modal for delete confirmation -->
    <v-dialog v-model="deleteVisible" max-width="290">
      <v-card>
        <v-card-title class="headline">{{ $t('download.delete.confirm.title') }}</v-card-title>

        <v-card-text>
          <p>{{ $t('download.delete.confirm.overview') }}</p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            flat="flat"
            @click="deleteVisible = false"
          >
            {{ $t('common.words.cancel') }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="error"
            @click="clickDeleteDownloadFinal()"
            :loading="loading.delete"
          >
            {{ $t('common.words.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import config from '../config/index.js';
import util from '../util.js';

import {UserDownloadStatuses} from 'august-constants';

export default {
  name: 'download',
  data() {
    return {
      constants: {
        UserDownloadStatuses,
      },
      loading: {
        create: false,
        delete: false,
      },
      visibility: {
        create: true,
        created: false,
        downloads: true,
      },
      includeInCopy: [{
        id: 'activity',
        avatar: 'list_alt',
        title: this.$i18n.t('download.create.activity.title'),
        subtitle: this.$i18n.t('download.create.activity.subtitle'),
        active: true,
      }, {
        id: 'videos',
        avatar: 'videocam',
        title: this.$i18n.t('download.create.videos.title'),
        subtitle: this.$i18n.t('download.create.videos.subtitle'),
        active: true,
      }, {
        id: 'images',
        avatar: 'photo_camera',
        title: this.$i18n.t('download.create.images.title'),
        subtitle: this.$i18n.t('download.create.images.subtitle'),
        active: true,
      }],
      headers: [{
        text: this.$i18n.t('download.current.header.created'),
        value: 'created',
        sortable: false,
      }, {
        text: this.$i18n.t('download.current.header.expires'),
        value: 'expires',
        sortable: false,
      }, {
        text: this.$i18n.t('download.current.header.status'),
        value: 'status',
        sortable: false,
      }, {
        text: '',
        value: '',
        sortable: false,
        width: '115',
      }],
      downloads: [],
      deleteVisible: false,
      deleteDownloadID: '',
    };
  },
  beforeCreate: async function () {
    try {
      const options = {headers: util.getRequestHeaders()};
      const result = await axios.get(`${config.apiBaseUrl}/downloads`, options);
      if (Array.isArray(result.data) && result.data.length) {
        this.downloads = result.data;
      }
    } catch (err) {
      console.log(err);

      this.$emit('alert', {
        text: this.$i18n.t('download.get.failure'),
        type: 'error'
      });

      await util.sendErrorLog(this.$options.__file, 'downloadBeforeCreateError', err.message);
    }
  },
  methods: {
    clickCreateDownload: async function () {
      this.loading.create = true;

      try {
        const url = `${config.apiBaseUrl}/downloads`;
        const options = {headers: util.getRequestHeaders()};
        const data = {
          brand: this.$root.brandID,
        };
        this.includeInCopy.forEach(item => {
          data[item.id] = item.active;
        });

        const result = await axios.post(url, data, options);

        const download = result.data;
        download.highlight = true;
        this.downloads.push(download);

        // redirect to the download/created view
        this.visibility.create = false;
        this.visibility.created = true;
      } catch (err) {
        console.log(err);

        if (_.get(err, 'response.status') === 400) {
          this.$emit('alert', {
            text: err.response.data.message,
            type: 'error'
          });
        } else {
          this.$emit('alert', {
            text: this.$i18n.t('download.create.failure'),
            type: 'error'
          });
        }

        await util.sendErrorLog(this.$options.__file, 'clickCreateDownloadError', err.message);
      }

      this.loading.create = false;
    },

    clickCreateNew: function () {
      this.visibility.create = true;
      this.visibility.created = false;
    },

    clickDeleteDownload: function (downloadID) {
      this.deleteVisible = true;
      this.deleteDownloadID = downloadID;
    },

    clickDeleteDownloadFinal: async function () {
      const downloadID = this.deleteDownloadID;

      for (let i = 0; i < this.downloads.length; i++) {
        const download = this.downloads[i];

        if (download._id === downloadID) {
          this.loading.delete = true;

          try {
            await this.deleteDownload(downloadID);

            this.downloads.splice(i, 1);
            this.$emit('alert', {
              text: this.$i18n.t('download.delete.success'),
              type: 'success'
            });
          } catch (err) {
            console.log(err);

            this.$emit('alert', {
              text: this.$i18n.t('download.delete.failure'),
              type: 'error'
            });

            await util.sendErrorLog(this.$options.__file, 'clickDeleteDownloadFinalError', err.message);
          }

          this.loading.delete = false;

          break;
        }
      }

      this.deleteVisible = false;
    },

    deleteDownload: async function (downloadID) {
      const options = {headers: util.getRequestHeaders()};
      const url = `${config.apiBaseUrl}/downloads/${downloadID}`;
      const result = await axios.delete(url, options);
    },
  },
};
</script>

<style>
  .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background-color: #fff !important;
  }
</style>
