<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-sheet class="elevation-1 pa-5">
        <h1 class="validate-from-link-title">{{ $t('validate-from-link.title') }}</h1>
        <div v-if="state === 'processing'">
            <v-layout justify-center>
              <div class="loader"></div>
            </v-layout>
        </div>
        <div v-if="state === 'success'">
          <v-layout justify-center>
            <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M96.9707 51.5C96.9707 76.6128 76.6128 96.9707 51.5 96.9707C26.3872 96.9707 6.02927 76.6128 6.02927 51.5C6.02927 26.3872 26.3872 6.02927 51.5 6.02927C76.6128 6.02927 96.9707 26.3872 96.9707 51.5ZM51.5 103C79.9427 103 103 79.9427 103 51.5C103 23.0573 79.9427 0 51.5 0C23.0573 0 0 23.0573 0 51.5C0 79.9427 23.0573 103 51.5 103ZM77.5415 40.6614C78.7189 39.4839 78.7189 37.5749 77.5415 36.3975C76.3641 35.2201 74.4551 35.2201 73.2776 36.3975L45.5294 64.1457L31.1319 49.7482C29.9545 48.5708 28.0455 48.5708 26.8681 49.7482C25.6906 50.9256 25.6906 52.8346 26.8681 54.012L43.3975 70.5415C44.5749 71.7189 46.4839 71.7189 47.6614 70.5415L77.5415 40.6614Z" fill="#00A2B8"/>
            </svg>
          </v-layout>
          <p class="validate-from-link-text">{{ $t('validate-from-link.success') }}</p>
        </div>
        <div v-if="state === 'failure'">
          <v-layout justify-center>
            <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M121.971 64C121.971 96.0163 96.0163 121.971 64 121.971C31.9836 121.971 6.02927 96.0163 6.02927 64C6.02927 31.9836 31.9836 6.02927 64 6.02927C96.0163 6.02927 121.971 31.9836 121.971 64ZM64 128C99.3462 128 128 99.3462 128 64C128 28.6538 99.3462 0 64 0C28.6538 0 0 28.6538 0 64C0 99.3462 28.6538 128 64 128ZM64 34.7961C61.5978 34.7961 59.6505 36.7435 59.6505 39.1456V70.4466C59.6505 72.8488 61.5978 74.7961 64 74.7961C66.4022 74.7961 68.3495 72.8488 68.3495 70.4466V39.1456C68.3495 36.7435 66.4022 34.7961 64 34.7961ZM64 85.9072C60.9115 85.9072 58.4078 88.4109 58.4078 91.4994C58.4078 94.5879 60.9115 97.0917 64 97.0917C67.0885 97.0917 69.5922 94.5879 69.5922 91.4994C69.5922 88.4109 67.0885 85.9072 64 85.9072Z" fill="#EE2B37"/>
            </svg>
          </v-layout>
          <p class="validate-from-link-text">{{ $t('validate-from-link.failure') }}</p>
        </div>
      </v-sheet>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';

import config from '../config/index.js';


export default {
  name: 'validate',
  data: function() {
    return {
      state: 'processing',
      res: null,
      err: ''
    }
  },
  computed: {
    token() {
      return new URL(window.location.href).searchParams.get('token');
    },
    idtype() {
      return new URL(window.location.href).searchParams.get('identifier');
    },
    code() {
      return new URL(window.location.href).searchParams.get('code');
    },
    brand: function () {
      return this.$root.brand;
    },
    brandID: function () {
      return this.$root.brandID;
    }
  },
  created: async function() {
    const url = `${config.apiBaseUrl}/validateFromLink`;
    const data = { 
      token: this.token,
      identifier: this.idtype,
      code: this.code,
      brand: this.brandID
    };
    try {
      const res = await axios.post(url, data);
      this.state = 'success';
      this.res = res;
    } catch(err) {
      this.state = 'failure'
      this.err = err;
    }
  }
}
</script>

<style>
.validate-from-link-title {

  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  align-items: center;
  text-align: center;

  color: #000000;
  margin-bottom: 25%;
}

.validate-from-link-text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;

  color: #000000;
  margin-top: 25%;
}

.loader {
  border: 16px solid #d1d1d1;
  border-top: 16px solid #3cb2e9;
  border-radius: 50%;
  width: 128px;
  height: 128px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
