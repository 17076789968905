<template>
  <v-container fluid :class="{'pl-2 pr-2': $vuetify.breakpoint.smAndDown}">
    <v-layout row wrap>
      <v-flex xs12 sm10 md8 lg6>
        <h2 class="mb-2">{{ $t('user.title') }}</h2>

        <v-sheet class="elevation-1 pa-3">
          <dl>
            <dt class="grey--text text--darken-2">{{ $t('user.inputs.first-name.label') }}</dt>
            <dd class="mb-2">{{ user.info.FirstName }} </dd>

            <dt class="grey--text text--darken-2">{{ $t('user.inputs.last-name.label') }}</dt>
            <dd class="mb-2">{{ user.info.LastName }} </dd>

            <dt class="grey--text text--darken-2">{{ $t('user.inputs.email.label') }}</dt>
            <dd class="mb-2">
              <span v-for="(email, index) in user.emails" v-bind:key="email">
                {{ email }}<span v-if="index < user.emails.length - 1">, </span>
              </span>
            </dd>

            <dt class="grey--text text--darken-2">{{ $t('user.inputs.phone.label') }}</dt>
            <dd class="mb-2">
              <span v-for="(phone, index) in user.phones" v-bind:key="phone">
                {{ phone }}<span v-if="index < user.phones.length - 1">, </span>
              </span>
            </dd>
          </dl>
        </v-sheet>

      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import util from '../util.js';

export default {
  name: 'user',
  data() {
    return {
      user: null,
    };
  },
  beforeCreate: async function () {
    this.user = await util.getUser();
  },
};
</script>
