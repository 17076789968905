<template>
  <div id="siwa">
    <div
        id="appleid-signin"
        data-type="sign-in"
        data-color="black"
        data-border="false"
        data-border-radius="6"
        data-width="200"
        data-height="36"
    ></div>
  </div>
</template>


<script setup>

import {onMounted, onBeforeUnmount} from 'vue';

import config from '../config/index.js';
import util from '../util';
import {getAppleNonce, postAppleSession} from '../lib/accountapiclient.js';
import {getUserLocale} from '../lib/i18n';

const emit = defineEmits(['siwaLoggedIn', 'siwaError']);


/**
 * Initializes Sign In With Apple button and event listeners
 */
onMounted(async () => {
  if (!window.AppleID) {
    console.error('AppleID is not defined, did you include the script?');
    return;
  }

  const {clientId, redirectURI } = config.signInWithApple;
  const scope = 'email name';
  const state = `origin:web:${Date.now().toString()}`;
  const nonce = await getAppleNonce();

  console.log('siwaButtonInit', {
    clientId,
    redirectURI,
    scope,
    state,
    nonce,
  });

  window.AppleID.auth.init({
    clientId,
    // NOTE: while redirectURI is required, it it's not used because we use the pop-up option
    // @see https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
    redirectURI,
    scope,
    state,
    nonce,
    usePopup: true
  });

  document.addEventListener('AppleIDSignInOnSuccess', onSiwaSuccess);
  document.addEventListener('AppleIDSignInOnFailure', onSiwaFailure);
});

/**
 * Destroy SIWA event listeners
 */
onBeforeUnmount(() => {
  document.removeEventListener('AppleIDSignInOnSuccess', onSiwaSuccess);
  document.removeEventListener('AppleIDSignInOnFailure', onSiwaFailure);
});

/**
 * Callback for AppleIDSignInOnSuccess event
 *
 * @param {object} event - Sign In With Apple auth event data
 */
async function onSiwaSuccess(event) {
  console.debug('onSiwaSuccessEvent', {
    event,
  });

  try {
    const decodedIdToken = decodeIdToken(event.detail.authorization.id_token);
    const {language, country} = getUserLocale();

    // log in using SIWA, proxied through account-api to rest-api,
    // if rest-api responds with success (rest-api access token), account-api returns an account-ui manage token
    const sessionPayload = {
      firstName: event.detail.user?.name?.firstName,
      lastName: event.detail.user?.name?.lastName,
      email: decodedIdToken.email,
      identityToken: event.detail.authorization.id_token,
      authorizationCode: event.detail.authorization.code,
      appleUserID: decodedIdToken.sub,
      language,
      country,
    };
    const manageToken = await postAppleSession(sessionPayload);

    console.log('onSiwaSuccessLoggedIn', {
      authorization: event.detail.authorization,
      decodedIdToken,
      sessionPayload,
      manageToken,
    });

    emit('siwaLoggedIn', manageToken);
  } catch (err) {
    console.error('onSiwaSuccessError', {
      event,
      err,
    });

    emit('siwaError', 'siwa.error');

    await util.sendErrorLog('SignInWithAppleButton', 'onSiwaSuccessError', err.message, {
      data: event,
    });
  }
}

/**
 * Callback for AppleIDSignInOnFailure event
 *
 * @param {object} event
 */
async function onSiwaFailure(event) {
  console.error('onSiwaFailure', {
    event,
  });

  emit('siwaError', 'siwa.error');

  await util.sendErrorLog('SignInWithAppleButton', 'onSiwaFailure', event.detail.error.message, {
    data: event,
  });
}

/**
 * Extracts user data from token returned by Apple on successful sign in
 *
 * @param {string} token - JWT
 * @returns {object}
 */
function decodeIdToken(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (component) {
    return '%' + ('00' + component.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

</script>


<style>
#siwa {
  margin: 10px auto 0;
  width: 200px;
}

#appleid-signin {
  width: 200px;
}
</style>
