<template>
  <v-container fluid :class="{'pl-2 pr-2': $vuetify.breakpoint.smAndDown}">
    <v-layout row wrap>
      <v-flex xs12 sm10 md8 lg6>
        <h2 class="pb-1">{{ $t('home.title') }}</h2>
        <p>{{ $t('home.overview') }}</p>

        <v-list three-line subheader class="elevation-1">
          <v-list-tile
            v-for="page in pages"
            :key="$t(page.title)"
            @click="$router.push(page.path)"
          >
            <v-list-tile-avatar class="hidden-xs-only">
              <v-icon>{{ page.icon }}</v-icon>
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title>{{ $t(page.title) }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ $t(page.info) }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import config from '../config/index.js';

export default {
  data() {
    return {
      pages: config.pages.filter(page => page.title !== 'nav.home.title')
    };
  },
};
</script>
