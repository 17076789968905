<template>
  <v-container fluid :class="{'pl-2 pr-2': $vuetify.breakpoint.smAndDown}">
    <v-layout row wrap>
      <v-flex xs12 sm10 md8 lg6>
        <h2 class="pb-1">{{ $t('delete-user.title') }}</h2>
        <p>{{ $t('delete-user.overview') }}</p>

        <v-sheet class="elevation-1 pa-3">
          <ul class="delete-consequences">
            <li v-if="user.lastOwnerLocks.length || user.lastOwnerDoorbells.length">
              {{ $t('delete-user.consequence.line1') }}
              <v-layout>
                <v-flex v-if="user.lastOwnerLocks.length">
                  <strong>{{ $t('common.words.locks') }}</strong>
                  <ul>
                    <li v-for="lock in user.lastOwnerLocks" v-bind:key="lock.name">
                      {{ lock.name }}
                      {{ $t('delete-user.attached-to') }}
                      {{ lock.house }}
                    </li>
                  </ul>
                </v-flex>

                <v-flex v-if="user.lastOwnerDoorbells.length">
                  <strong>{{ $t('common.words.doorbells') }}</strong>
                  <ul>
                    <li v-for="doorbell in user.lastOwnerDoorbells" v-bind:key="doorbell.name">
                      {{ doorbell.name }}
                      {{ $t('delete-user.attached-to') }}
                      {{ doorbell.house }}
                    </li>
                  </ul>
                </v-flex>
              </v-layout>
            </li>
            <li v-if="user.lastOwnerLocks.length || user.lastOwnerDoorbells.length">
              {{ $t('delete-user.consequence.line2a') }}
            </li>
            <li v-else>
              {{ $t('delete-user.consequence.line2b') }}
            </li>
            <li v-if="user.apps.length">
              {{ $t('delete-user.consequence.line3') }}
              <ul>
                <li v-for="app in user.apps" v-bind:key="app">{{ app }}</li>
              </ul>
            </li>
            <li>{{ $t('delete-user.consequence.line4') }}</li>
            <li>{{ $t('delete-user.consequence.line5') }}</li>
            <li>{{ $t('delete-user.consequence.line6') }}</li>
          </ul>

          <p><strong>{{ $t('delete-user.notes.intro') }}</strong> {{ $t('delete-user.notes.line1') }}</p>
          <p><strong>{{ $t('delete-user.notes.intro') }}</strong> {{ $t('delete-user.notes.line2') }}</p>

          <v-btn color="error" class="ma-0 mt-3" @click="clickDeleteAccount">{{ $t('delete-user.button') }}</v-btn>
        </v-sheet>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">{{ $t('delete-user.confirm.title') }}</v-card-title>

        <v-card-text>
          <p>{{ $t('delete-user.confirm.overview') }}</p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            flat="flat"
            @click="dialog = false"
          >
            {{ $t('common.words.cancel') }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="error"
            @click="clickDeleteAccountFinal"
            :loading="loading"
          >
            {{ $t('common.words.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import config from '../config/index.js';
import util from '../util.js';

export default {
  name: 'deleteUser',
  data() {
    return {
      user: null,
      dialog: false,
      loading: false,
    };
  },
  beforeCreate: async function () {
    this.user = await util.getUser();
  },
  methods: {
    clickDeleteAccount: function () {
      this.dialog = true;
    },
    clickDeleteAccountFinal: async function () {
      this.loading = true;

      try {
        const options = {
          headers: util.getRequestHeaders(),
          params: {
            brand: this.$root.brandID,
          }
        };
        const url = `${config.apiBaseUrl}/user`;
        const result = await axios.delete(url, options);

        this.$emit('alert', {
          text: this.$i18n.t('delete-user.message.success'),
          type: 'success'
        });
        this.$emit('logout');
      } catch (err) {
        console.log(err);

        this.$emit('alert', {
          text: this.$i18n.t('delete-user.message.failure'),
          type: 'error'
        });

        await util.sendErrorLog(this.$options.__file, 'clickDeleteAccountFinalError', err.message);
      }

      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>

<style>
.upper {
  text-transform: uppercase;
}
.delete-consequences {
  list-style-type: decimal;
}
.delete-consequences > li {
  margin-bottom: 1rem;
}
</style>
