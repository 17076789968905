<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8 md8 lg6>

        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t('validate.title') }}</v-toolbar-title>
        </v-toolbar>
        <v-sheet class="elevation-1 pa-3">
          <p>{{ $t('validate.intro', {phoneNumber}) }}</p>
          <p>{{ $t('validate.forgotPassword', {phoneNumber, email}) }}</p>
          <p>{{ $t('validate.newAccount', {code}) }}</p>

          <p class="text-xs-center ma-0 mt-3">
            <a color="primary" @click="$router.push({name: 'login'});">{{ $t('common.buttons.login.label') }}</a> |
            <a color="primary" @click="$router.push({name: 'reset'});">{{ $t('loginResetPasswordLink') }}</a>
          </p>
        </v-sheet>


      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import util from "../util.js";

export default {
  name: 'validate',
  computed: {
    phoneNumber() {
      return new URL(window.location.href).searchParams.get('p');
    },
    email() {
      const email = new URL(window.location.href).searchParams.get('e');

      return `${email.substring(0, 2)}...${email.substring(2)}`;
    },
    code() {
      return new URL(window.location.href).searchParams.get('c');
    }
  },
}
</script>

<style>

</style>
