<template>
  <v-container fluid :class="{'pl-2 pr-2': $vuetify.breakpoint.smAndDown}">
    <v-layout row wrap>
      <v-flex xs12 sm10 md8 lg6>
        <h2 class="pb-1">{{ $t('factory-reset.request.title') }}</h2>
        <p>{{ $t('factory-reset.request.overview') }}</p>
      </v-flex>
    </v-layout>

    <!-- Existing factory Reset Requests For Someone Else -->
    <v-layout row wrap v-show="visibility.factoryResetRequests" class="mt-3">
      <v-flex xs12 sm10 md8 lg6>
        <v-card>
          <v-card-title primary-title class="pb-1 pt-3">
            <h2>{{ $t('factory-reset.request.other.title') }}</h2>
          </v-card-title>

          <v-card-text class="pa-0">
            <p class="pt-1 pr-3 pl-3">{{ $t('factory-reset.request.other.overview') }}</p>

            <v-data-table
              hide-actions
              :headers="headers"
              :items="factoryResetRequestsForSomeoneElse"
              item-key="name"
              class="elevation-0"
              @click="$router.push('/')"
            >
              <template slot="no-data">
                <p class="text-xs-center mt-3 mb-3">{{ $t('factory-reset.request.none') }}</p>
              </template>

              <template slot="items" slot-scope="props">
                <tr class="request-table-elem-for-someone-else" >
                  <td>{{ props.item.humanReadableTime }}</td>
                  <td>{{ props.item.serialNumber }}</td>
                  <td id="device-status-someone-else" :class="props.item.status"> {{props.item.status}} </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Existing factory Reset Requests For You -->
    <v-layout row wrap v-show="visibility.factoryResetRequests" class="mt-5">
      <v-flex xs12 sm10 md8 lg6>
        <v-card>
          <v-card-title primary-title class="pb-1 pt-3">
            <h2>{{ $t('factory-reset.request.yours.title') }}</h2>
          </v-card-title>

          <v-card-text class="pa-0">
            <p class="pt-1 pr-3 pl-3">{{ $t('factory-reset.request.yours.overview') }}</p>

            <v-data-table
              hide-actions
              :headers="headers"
              :items="factoryResetRequestsForYou"
              item-key="name"
              class="elevation-0"
              @click="$router.push('/')"
            >
              <template slot="no-data">
                <p class="text-xs-center mt-3 mb-3">{{ $t('factory-reset.request.none') }}</p>
              </template>

              <template slot="items" slot-scope="props">
                <tr class="request-table-elem-for-you" v-on:click="clickPendingFactoryResetRequest(props.item)">
                  <td>{{ props.item.humanReadableTime }}</td>
                  <td>{{ props.item.serialNumber}}</td>
                  <td id="device-status-you" :class="props.item.status"> {{props.item.status}} </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import config from '../config/index.js';
  import {getCurrentLanguage} from '../lib/i18n.js';
  import util from '../util.js';

  import { UserFactoryResetRequestStatuses} from 'august-constants';

  export default {
    name: 'factoryresetrequest',
    data() {
      return {
        constants: {
          UserFactoryResetRequestStatuses,
        },
        visibility: {
          factoryResetRequests: true,
        },
        headers: [{
          text: this.$i18n.t('factory-reset.request.header.created'),
          value: 'created',
        }, {
          text: this.$i18n.t('factory-reset.request.header.serial'),
          value: 'serial_number',
        }, {
          text: this.$i18n.t('factory-reset.request.header.status'),
          value: 'status',
        }],
        factoryResetRequestsForYou: [],
        factoryResetRequestsForSomeoneElse: [],
      };
    },
    beforeCreate: async function () {
      try {
        const options = {headers: util.getRequestHeaders()};
        const result = await axios.get(`${config.apiBaseUrl}/factoryresetrequests`, options);
        if (Array.isArray(result.data.factoryResetRequests) && result.data.factoryResetRequests.length) {
          // Note: LockName is only set if the user is an owner of the lock at the time of the request
          this.factoryResetRequestsForYou = result.data.factoryResetRequests.filter(request => !!request.LockName);
          this.factoryResetRequestsForSomeoneElse = result.data.factoryResetRequests.filter(request => !request.LockName);
        }

        let {locale} = getCurrentLanguage();
        const dateTimeFormat = new Intl.DateTimeFormat(locale, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
        this.factoryResetRequestsForYou.map(doc => {
          doc.humanReadableTime = dateTimeFormat.format(new Date(doc.createdAt));
        });
        this.factoryResetRequestsForSomeoneElse.map(doc => {
          doc.humanReadableTime = dateTimeFormat.format(new Date(doc.createdAt));
        });

      } catch (err) {
        console.log(err);

        this.$emit('alert', {
          text: this.$i18n.t('factory-reset.request.create.failure'),
          type: 'error'
        });

        await util.sendErrorLog(this.$options.__file, 'factoryresetrequestsBeforeCreateError', err.message);
      }
    },
    methods: {
      clickPendingFactoryResetRequest: async function(factoryResetRequest) {
        try {
          if (factoryResetRequest.status === UserFactoryResetRequestStatuses.APPROVED || factoryResetRequest.status === UserFactoryResetRequestStatuses.DENIED) {
            this.$emit('alert', {
              text: 'Cannot change a request that has already been processed.',
              type: 'success'
            });
            return;
          }
          console.log(factoryResetRequest);
          this.$router.push({
            name:'processFactoryResetRequest',
            params: {
              id:factoryResetRequest._id,
              LockName:factoryResetRequest.LockName,
              serialNumber:factoryResetRequest.serialNumber
            }
          });
        } catch(err) {
          console.log(err);
        }
      },
    },
  };
</script>

<style>
  .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background-color: #fff !important;
  }
  tr:hover td {
    background-color: rgb(245,245,245);
  }
  .request-table-elem-for-you:hover {
    cursor: pointer;
  }
</style>
