'use strict';

import Vue from 'vue';
import VeeValidate from 'vee-validate';

import './plugins/vuetify';
import App from './App.vue';
import router from './router/index';
import './registerServiceWorker';
import { i18n, initVueI18n, initLanguages } from './lib/i18n';
import util from './util.js';
import {default as store, initBrand} from './store';

Vue.use(VeeValidate);

Vue.config.productionTip = false;

Vue.config.errorHandler = async function (err, vm, info) {
  await util.sendErrorLog('main.js', 'uncaughtExceptionError', err.message, {
    data: {
      info,
    },
  });
}

// Vue instance
let vm;

initBrand()
  .then(function initLanguageHandling() {
    initVueI18n();
    return initLanguages();
  })
  .then(function initVue() {
    vm = new Vue({
      data: store,
      router,
      render: h => h(App),
      i18n,
    }).$mount('#app');
  });
